const palette = {
	gray: [
		"#F8F9FA",
		"#F1F3F5",
		"#E9ECEF",
		"#DEE2E6",
		"#CED4DA",
		"#ADB5BD",
		"#868E96",
		"#495057",
		"#343A40",
		"#212529",
	],
	viloet: [
		"#f3f0ff",
		"#e5dbff",
		"#d0bfff",
		"#b197fc",
		"#9775fa",
		"#845ef7",
		"#7950f2",
		"#7048e8",
		"#6741d9",
		"#5f3dc4",
	],
	red: [
		"#fff5f5",
		"#ffe3e3",
		"#ffc9c9",
		"#ffa8a8",
		"#ff8787",
		"#ff6b6b",
		"#fa5252",
		"#f03e3e",
		"#e03131",
		"#c92a2a",
	],
};

export default palette;
